@import './variables';

.current-listings {
    margin-bottom: 9rem;
    display: none;

    h1 {
        text-align: center;
        padding: 1rem;
        font-size: $h1;
    }

    .current {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(1, 1fr);
        grid-gap: 3rem;
        padding: 1rem;
        padding-right: 3rem;
        padding-left: 3rem;
        padding-top: 0;
    
        &__info {
            padding: .5rem;
            color: $primary;

            .current__info-address {
                font-size: 1.5rem;
            }
        }
    
        &__image {
            width: 100%;
            overflow: hidden;
            line-height: .6;
    
            img {
                width: 100%;
                transition: transform .50s ease-in-out;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }
}

@media (max-width: $mobile) {
    .current-listings {
        .current {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}
