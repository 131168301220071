html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

html,
body, h1,h2,h3,h4,h5,h6,p,a,li, button, input, textarea, label {
    padding: 0;
    margin: 0;
    font-family: 'Playfair Display', serif;
    font-weight: 400;
}

li {
    list-style: none;
}

#footer {
    margin-top: auto;
}