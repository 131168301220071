@import './variables';

.home-about {
    display: flex;
    margin-top: 5rem;
    padding-right: 3rem;
    padding-left: 3rem;

    &__text {
        position: sticky;
        top: 10rem;
        width: 80%;
        height: 100%;
        margin-right: 3rem;
        display: flex;
        flex-direction: column;
        padding-top: 0rem;
        font-size: 1.1rem;

        h1 {
            font-size: $h1;
            line-height: 1;
        }

        h1, p:not(:nth-child(4)) {
            margin-bottom: 1.5rem;
        }
    }
}

@media (max-width: 1000px) {
    .home-about{
        flex-direction: column;
        padding-right: 2rem;
        padding-left: 2rem;

        .slides {
            order: 2;
        }

        &__text {
            order: 1;
            position: relative;
            top: 0rem;
            margin-bottom: 2rem;
            width: 90%;
        }
    }
}

@media (min-width: 1750px) {
    .home-about {
    
        &__text {
            font-size: 1.4rem;
        }
    }
}

@media (min-width: 2050px) {
    .home-about {
    
        &__text {
            font-size: 1.6rem;
        }
    }
}