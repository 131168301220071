@import './variables';

.awssld__content {
    position: relative;

    h1 {
        position: absolute;
        bottom: 2rem;
        left: 3rem;
        color: $secondary;
        font-size: $h1;
        text-transform: uppercase;
    }
}

@media (max-width: 700px) {
    .awssld__content {
    
        h1 {
            font-size: 1.5rem;
            bottom: 1rem;
            left: 2rem;
        }
    }
}