@import './variables';
@import './mixins';

.contact {
    width: 100%;
    height: 100%;
    margin-top: 6rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;


    .contact-form {
        animation: slide 1s ease-in;
        margin-top: 2rem;
    }
}

@keyframes slide {
    from {
        transform: translateY(40px);
        opacity: 0;
    }
    to {
        transform: translateY(0px);
        opacity: 1;
    }
}

@media (max-width: $mobile) {
    .contact {

        .contact-form {
            width: calc(100% - 2rem);
            h1 {
                font-size: 1.5rem;
                margin: 0 auto;
                width: calc(100% - 2rem);
            }
        }
    }
}