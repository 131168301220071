@import './variables';

.side-images {
    
    .img-container {
        width: 100%;
        position: sticky;
        top: 10rem;
        margin-bottom: 1rem;

        h1 {
            position: absolute;
            bottom: 0rem;
            padding: 1.1rem;
            color: $secondary;
            width: 100%;
            font-size: 32px;
            text-transform: uppercase;
        }

        img {
            width: 100%;
        }
    }
}

@media (max-width: $mobile) {
    .side-images {
        margin-left: 0;

        .img-container {
            top: 5.5rem;
            margin-top: 0;

            h1 {
                font-size: 24px;
            }
        }
    }
}