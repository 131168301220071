@import './variables';

// navigation 

@mixin  navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
    transition: all .50s ease-in-out;

    .nav__logo {
        margin-left: 3rem;

        a:link, a:visited {
            text-decoration: none;
        }
    }

    .nav__list {
        width: 28rem;
        text-transform: uppercase;
        margin-right: 3rem;
        color: $secondary;

        ul {
            display: flex;
            justify-content: space-between;

            li {
                list-style: none;

                a:link, a:visited {
                    text-decoration: none;
                }
            }
        }
    }

    @media (max-width: 700px) {
        .nav__logo {
            margin-left: 2rem;
        }
    }
}

@mixin burgerBars {
    position: absolute;
    width: 35px;
    height: 3px;
    transition: all .30s ease-in-out;

    &:nth-child(1) {
        top: 0;
    }

    &:nth-child(2) {
        top: 8px;
    }

    &:nth-child(3) {
        top: 16px;
    }

    .open > & {
        transition: all .30s ease-in-out;
    }

    .open > &:first-child {
        top: .7rem;
        transform: rotate(135deg);
        transition: all .30s ease-in-out;
    }

    .open > &:nth-child(2) {
        opacity: 0;
        bottom: -60px;
        transition: all .30s ease-in-out;
    }

    .open > &:nth-child(3) {
        top: .7rem;
        transform: rotate(-135deg);
        transition: all .30s ease-in-out;
    }
}

// Buttons

@mixin btn {
    position: relative;
    border: 1.5px solid $primary;
    width: 15rem;
    text-align: center;
    margin: 0 auto;
    cursor: pointer;
    overflow-x: hidden;

    a:link, a:visited {
        position: relative;
        display: block;
        z-index: 999;
        text-decoration: none;
        color: $primary;
        padding: .5rem;
        transition: all 400ms ease-in-out;

        &:hover {
            color: $secondary;
        }
    }

    &::after {
        content: "";
        width: 0;
        height: 100%;
        background: linear-gradient(135deg, #000 50%, transparent 50%);
        position: absolute;
        top: 0;
        left: 0;
        transition: all 200ms ease-in-out;
    }

    &:hover::after {
        width: 220%;
    }
}

// recently sold styles

@mixin recently-sold {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-gap: 3rem;
    padding: 1rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 0;
    
        &__info {
            padding: .5rem;
            color: $primary;

            .recent__info-address {
                font-size: 1.5rem;
            }
        }
    
        &__image {
            width: 100%;
            line-height: .6;
            overflow: hidden;
    
            img {
                width: 100%;
                transition: transform .50s ease-in-out;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }

        @media (max-width: $mobile) {
            padding-left: 2rem;
            padding-right: 2rem;
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .btn-container {
        margin-top: 1rem;
        margin-bottom: 2rem;

        h3 {
           @include btn;
        }
    }