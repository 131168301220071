@import './variables';
@import './mixins';

.navigation {
    @include navigation;
    background: $secondary;

    .nav__logo {
        color: $primary;

        a:link, a:visited {
            color: $primary;
        }
    }

    .nav__list {
        color: $primary;

        ul {

            li {
                color: $primary;

                a:link, a:visited {
                    color: $primary;
                }
            }
        }
    }
}

.nav {
    @include navigation;

    .nav__logo {
        color: $secondary;

        h1 {
            font-size: $h1;
        }

        a:link, a:visited {
            color: $secondary;
        }
    }

    .nav__list {
        color: $secondary;

        ul {

            li {
                color: $secondary;

                a:link, a:visited {
                    color: $secondary;
                }
            }
        }
    }
}

.scrolled {
    @include navigation;
    background: $secondary;

    .nav__logo {
        color: $primary;

        a:link, a:visited {
            color: $primary;
            font-size: 32px;
        }
    }

    .nav__list {
        color: $primary;

        ul {

            li {
                color: $primary;

                a:link, a:visited {
                    color: $primary;
                }
            }
        }
    }
}

.hamburger {
    position: relative;
    width: 35px;
    height: 25px;
    cursor: pointer;
    margin-right: 2rem;
    z-index: 1050;
    display: none;
}

.hamburger__bar-scroll {
    @include burgerBars;
    background: $secondary;
}

.hamburger__bar {
    @include burgerBars;
    background: $secondary;
}

.overlay {
    width: 100%;
    min-width: 100%;
    height: 100vh;
    min-height: 100vh;
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 2rem;
    background: #DBCABA;
    color: $secondary;
    left: 110%;
    z-index: 1000;
    transition: left .30s ease-in-out;

    &.show {
        left: 0;
    }

    &__list {

        a:link, a:visited {
            color: $secondary;
            text-decoration: none;
        }

        ul {
            padding: 0;
            margin-top: -5rem;

            li:not(:nth-child(3)) {
                margin-bottom: 1rem;
            }
        }
    }
}

@media (max-width: $mobile) {
    .nav, .scrolled {

        &__list {
            display: none;
        }

        .hamburger {
            display: block;
        }
    }

    .scrolled {
        .hamburger {
            
            .hamburger__bar-scroll {
                background: $primary;
            }
        }

        .open > .hamburger__bar-scroll {
            background: $secondary;
        }
    }

    .nav {
        @include navigation;
    
        .nav__logo {
    
            h1 {
                font-size: 32px;
            }
    
            a:link, a:visited {
                color: $secondary;
            }
        }
    }
}