@import './mixins';
@import './variables';

.contact-form {
    width: 35rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    input, textarea, label {
        margin-bottom: .5rem;
        font-size: 1.1rem;
    }

    input, textarea {
        padding: .5rem;
        border: 1.5px solid $primary;
    }

    textarea {
        margin-bottom: 1rem;
    }

    span {
        position: relative;
        border: 1.5px solid $primary;
        width: 15rem;
        text-align: center;
        margin: 0 auto;
        cursor: pointer;
        overflow-x: hidden;

        .submit-btn {
            border: none;
            position: relative;
            width: 15rem;
            margin: 0;
            background: transparent;
            font-size: 1rem;
            text-transform: uppercase;
            display: block;
            z-index: 998;
            text-decoration: none;
            color: $primary;
            padding: .5rem;
            cursor: pointer;
            transition: all 400ms ease-in-out;

            &:hover {
                color: $secondary;
            }
        }

        &::after {
            content: "";
            display: block;
            width: 0;
            height: 100%;
            background: linear-gradient(135deg, #000 50%, transparent 50%);
            position: absolute;
            top: 0;
            left: 0;
            transition: all 200ms ease-in-out;
        }
    
        &:hover::after {
            width: 220%;
        }
    }
}

@media (max-width: $mobile) {
    .contact-form {
        width: 100%;
    }

    textarea, input[type="text"],
    input[type="email"]{ font-size: 16px; }
}

@media screen and (device-aspect-ratio: 9/16) {
    select, textarea, input[type="text"],
    input[type="email"]{ font-size: 16px; }
}

@media screen and (device-aspect-ratio: 375/667) {
    select, textarea, input[type="text"],
    input[type="email"]{ font-size: 16px; }
}

@media screen and (device-aspect-ratio: 40/71) {
    select, textarea, input[type="text"],
    input[type="email"]{ font-size: 16px; }
}