@import './variables';
@import './mixins';

.testimonials {
    margin-top: 4rem;
    margin-bottom: 4rem;
    height: 75vh;

    &__body {
        width: 63%;
        min-width: 500px;
        height: 100%;
        padding: 2rem;
        margin: 0 auto;
        margin-bottom: 4rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .active {
            animation: opacity-1 1s ease-in-out;
        }

        .active-2 {
            animation: opacity-2 1s ease-in-out;
        }

        h1 {
            font-size: $h1;
            margin-bottom: 5rem;
        }

        .quote-symbol {
            font-size: 8rem;
            text-align: center;
            line-height: .2;
            opacity: calc(40 / 100);
            margin-bottom: 10px;
        }

        .quote {
            text-align: center;
            font-size: 17.6px;
            font-weight: 700;
            margin-bottom: 15px;
        }

        .author {
            text-align: center;
            margin-bottom: 22px;
            font-size: 1.5rem;
            opacity: calc(80 / 100);
        }

        .blurb {
            margin-bottom: 30px;
            opacity: calc(80 / 100);
            font-size: 17.6px;
        }

        .testimonials__btn {
            margin-top: 2rem;

            h3 {
                @include btn;
            }
        }
    }
}

@media (max-width: $mobile) {
    .testimonials {

        &__body {
            width: calc(100% - 4rem);
            min-width: calc(100% - 4rem);
            background: $secondary;
            padding: 0;
            top: 5rem;
            z-index: 9;

            .blurb {
                font-size: 1.1rem;
            }
        }
    }
}

@keyframes opacity-1 {
    from {
        transition: opacity .3s ease-in-out;
        opacity: 0;
    }
    to {
        transition: opacity .3s ease-in-out;
        opacity: 1;
    }
}
@keyframes opacity-2 {
    from {
        transition: opacity .3s ease-in-out;
        opacity: 0;
    }
    to {
        transition: opacity .3s ease-in-out;
        opacity: 1;
    }
}