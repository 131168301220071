@import './variables';
@import './mixins';

.home-recent-sold {
    margin-top: 2rem;
    margin-bottom: 6rem;

    h1 {
        text-align: center;
        padding: 1rem;
        font-size: $h1;
    }

    .recent {
        @include recently-sold;
    }

    .btn-container {
        margin-top: 1rem;
        margin-bottom: 2rem;

        h3 {
           @include btn;
        }
    }
}

@media (max-width: $mobile) {
    .home-recent-sold {
        .recent {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}