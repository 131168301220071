@import './variables';
@import './mixins';

.recent-sold {
    height: 100%;
    width: 100%;
    margin-bottom: 3rem;
    .recent-sold-inner {
        margin-top: 8rem;
        animation: slide 1s ease-in;

        h1 {
            text-align: center;
            margin-bottom: 2rem;
            font-size: $h1;
        }
    
        .recent {
            @include recently-sold;
            margin-bottom: 3rem;
        }
    }

    .nav-btns {
        width: calc(100% - 6rem);
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
    }

    .back, .next {
        position: relative;
        border: 1.5px solid $primary;
        width: 15rem;
        text-align: center;
        margin: 0 auto;
        cursor: pointer;
        overflow-x: hidden;

        &:hover {
            color: $secondary;
        }

        span {
            position: relative;
            display: block;
            z-index: 2;
            text-decoration: none;
            color: $primary;
            padding: .5rem;
            font-size: 18.72px;
            transition: all 400ms ease-in-out;
    
            &:hover {
                color: $secondary;
            }
        }

        background: none;

        &::after {
            content: "";
            width: 0;
            height: 100%;
            background: linear-gradient(135deg, #000 50%, transparent 50%);
            position: absolute;
            top: 0;
            left: 0;
            transition: all 200ms ease-in-out;
        }
    
        &:hover::after {
            width: 260%;
        }
    }
}

@media (max-width: $mobile) {
    .recent-sold {
        .nav-btns {
            width: calc(100% - 4rem);
        }
    
        .recent-sold-inner {
            .back, .next {
                width: 5rem;
            }
        }

        span {
            &:hover {
                color: $primary;
            }
        }
    }
}

@keyframes slide {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}