@import './variables';
@import './mixins';

.testimonial-page {
    margin: 7rem 3rem;

    h1:not(:nth-child(1)) {
        margin-bottom: 6rem;
        text-align: center;
        font-size: $h1;
    }

    &__section {
        display: flex;
        flex-direction: column;
        width: 65%;
        animation: slide 1s ease-in;
        margin: 0 auto;
        margin-bottom: 2rem;

        .testimonial-container {
            margin-bottom: 152px;

            .quote-symbol {
                font-size: 8rem;
                text-align: center;
                line-height: .2;
                opacity: calc(40 / 100);
                margin-bottom: 10px;
            }
    
            .quote {
                text-align: center;
                font-size: 17.6px;
                font-weight: 700;
                margin-bottom: 24px;
            }
    
            .author {
                text-align: center;
                margin-bottom: 32px;
                font-size: 1.5rem;
                opacity: calc(80 / 100);
            }
    
            .blurb {
                margin-bottom: 40px;
                opacity: calc(80 / 100);
            }
    
            hr {
                opacity: calc(50 / 100);
            }
        }
    }

    span {
        position: relative;
        display: block;
        z-index: 999;
        text-decoration: none;
        color: $primary;
        padding: .5rem;
        font-size: 18.72px;
        transition: all 400ms ease-in-out;

        &:hover {
            color: $secondary;
        }
    }
}

@media (max-width: $mobile) {
    .testimonial-page {
        margin: 7rem 2rem;
    
        &__section {
            width: 100%;
        }
    }
}

@keyframes slide {
    from {
        transform: translateY(40px);
        opacity: 0;
    }
    to {
        transform: translateY(0px);
        opacity: 1;
    }
}