@import './variables';

.footer {
    background: $primary;
    color: $secondary;
    width: 100%;
    height: 5rem;

    a:link, a:visited {
        color: $secondary;
        text-decoration: none;
    }

    &__content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .footer__info {
            display: flex;
            margin-left: 3rem;
            line-height: 1.5;

            p {
                margin-left: .5rem;
                margin-right: .5rem;
            }
        }

        .footer__logo {
            width: 15%;
            margin-right: 3rem;

            img {
                width: 100%;
            }
        }

    }
}

@media (max-width: $mobile) {
    .footer {
        height: 6rem;
    
        &__content {
           flex-direction: column;
           justify-content: initial;

           .footer__info {
               margin-top: 1.5rem;
               margin-left: 0;
                p {
                    font-size: .7rem;
                }
           }
           .footer__logo {
               width: 20%;
               min-width: 150px;
               margin: .5rem 0;
           }
        }
    }
}